import {
  DeleteFilled,
  MenuOutlined,
  PauseCircleFilled,
  PlayCircleFilled,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Layout,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api, axios_error } from "../../../config";
import UpdateProductUnavailability from "./UpdateProductUnavailability";

function ProductsList() {
  const [products, setProducts] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = React.useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={`${selectedKeys[0] || ""}`}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({
                  closeDropdown: false,
                });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? "#1890ff" : undefined,
          }}
        />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex
          ? // <Highlighter
            //   highlightStyle={{
            //     backgroundColor: "#ffc069",
            //     padding: 0,
            //   }}
            //   searchWords={[searchText]}
            //   autoEscape
            //   textToHighlight={text ? text.toString() : ""}
            // />
            text
          : text,
    }),
    [searchedColumn]
  );

  const navigate = useNavigate();

  const _get_products = useCallback(() => {
    setSpinning(true);
    api
      .get(`/admin/products`)
      .then((res) => {
        setProducts(res.data);
        setSpinning(false);
      })
      .catch((err) => {
        axios_error(err);
        setSpinning(false);
      });
  }, []);

  useEffect(() => {
    _get_products();
  }, [_get_products]);

  const _delete_product = useCallback(
    (r) => {
      api
        .delete(`/admin/product/${r.id}`)
        .then((_res) => {
          message.success("Product Deleted!");
          _get_products();
        })
        .catch((err) => {
          axios_error(err);
        });
    },
    [_get_products]
  );

  const toggleProductAvailability = useCallback(
    (r) => {
      let state = r.status === 1 ? "pause" : "resume";
      api
        .patch(`/admin/product/${r.id}/${state}`)
        .then((res) => {
          message.success(`Product availability ${state}d`);
          _get_products();
        })
        .catch((err) => {
          axios_error(err);
        });
    },
    [_get_products]
  );

  const columns = useMemo(() => {
    return [
      { title: "ID", dataIndex: "id" },
      {
        title: "Product Name",
        dataIndex: "name",
        // render: (v, r) => <Link to={`/product/${r.id}`}>{v}</Link>,
        ...getColumnSearchProps("name"),
      },
      { title: "Code", dataIndex: "code", ...getColumnSearchProps("code") },
      {
        title: "Vendor",
        dataIndex: "vendor",
        ...getColumnSearchProps("vendor"),
      },
      {
        title: "Available",
        dataIndex: "status",
        render: (v) => (
          <span>
            <Tag color={v === 1 ? "green" : "volcano"}>
              {v === 1 ? "Yes" : "No"}
            </Tag>
          </span>
        ),
      },
      {
        title: "Actions",
        render: (r) => (
          <Space>
            {r.status === 1 ? (
              <PauseCircleFilled
                className="action-icon"
                onClick={toggleProductAvailability.bind(this, r)}
              />
            ) : (
              <PlayCircleFilled
                className="action-icon"
                onClick={toggleProductAvailability.bind(this, r)}
              />
            )}
            <MenuOutlined
              className="action-icon"
              onClick={() => setModalOpen(true)}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={_delete_product.bind(this, r)}
            >
              <DeleteFilled className="action-icon error" />
            </Popconfirm>
          </Space>
        ),
      },
    ];
  }, [_delete_product, toggleProductAvailability, getColumnSearchProps]);

  return (
    <Layout.Content>
      <Row gutter={16}>
        <Col lg={12}>
          <Typography.Title level={3}>Products</Typography.Title>
        </Col>
        <Col lg={12} className="align-right">
          <Button type="primary" onClick={() => navigate("/categories")}>
            Categories
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={products}
        loading={spinning}
        rowKey="id"
      />

      <Button
        icon={<PlusOutlined />}
        type="primary"
        shape="circle"
        className="fab"
        onClick={() => navigate("/product/new")}
      />

      <UpdateProductUnavailability
        open={modalOpen}
        close={() => setModalOpen(false)}
      />
    </Layout.Content>
  );
}

export default ProductsList;
