import { Route, Routes } from "react-router-dom";
import { Logout } from "../guest";
import {
  CompaniesList,
  CompanyDetails,
  CompanyForm,
  CompanyUserPlan,
  CompanyUsersList,
} from "./companies";
import { Dashboard } from "./dashboard";
import { OrdersList } from "./orders";
import { CategoriesList, ProductForm, ProductsList } from "./products";
import ProductDetails from "./products/ProductDetails";
import Profile from "./Profile";
import { Settings } from "./settings";
import { SupportTicketsList } from "./support";
import { VendorDetails, VendorForm, VendorsList } from "./vendors";

function AdminRouter() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />

      <Route path="/categories" element={<CategoriesList />} />

      <Route path="/products" element={<ProductsList />} />
      <Route path="/product/new" element={<ProductForm />} />
      <Route path="/product/:id" element={<ProductDetails />} />
      <Route path="/product/:id/edit" element={<ProductForm />} />

      <Route path="/vendors" element={<VendorsList />} />
      <Route path="vendor/new" element={<VendorForm />} />
      <Route path="/vendor/:id" element={<VendorDetails />} />
      <Route path="/vendor/:id/edit" element={<VendorForm />} />

      <Route path="/companies" element={<CompaniesList />} />
      <Route path="/company/new" element={<CompanyForm />} />
      <Route path="/company/:id" element={<CompanyDetails />} />
      <Route path="/company/:id/edit" element={<CompanyForm />} />
      <Route path="/company/:id/users" element={<CompanyUsersList />} />
      <Route
        path="/company/:id/user/:userId/plan"
        element={<CompanyUserPlan />}
      />

      <Route path="/orders" element={<OrdersList />} />

      <Route path="/support" element={<SupportTicketsList />} />

      <Route path="/settings" element={<Settings />} />

      <Route path="/profile" element={<Profile />} />

      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
}

export { AdminRouter };
