import {
  DeleteFilled,
  EditFilled,
  ImportOutlined,
  PlusOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Layout,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
  Upload,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, axios_error, baseurl, getToken } from "../../../config";
import CompanyUserForm from "./CompanyUserForm";

export default function CompanyUsersList() {
  const [modalOpen, setModalOpen] = useState(false);
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const __get_company_users = useCallback((id) => {
    setLoading(true);
    api
      .get(`/admin/company/${id}/users`)
      .then((res) => {
        setUsers(res.data);
        setLoading(false);
      })
      .catch((err) => {
        axios_error(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    __get_company_users(id);
  }, [__get_company_users, id]);

  const __remove_company_user = useCallback(
    (r) => {
      api
        .delete(`/admin/company/user/${r.id}`)
        .then((res) => {
          __get_company_users(id);
          message.success("Company user delete");
        })
        .catch((err) => {
          axios_error(err);
        });
    },
    [id, __get_company_users]
  );

  const columns = useMemo(() => {
    return [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      { title: "Email", dataIndex: "email" },
      { title: "Emp ID", dataIndex: "employee_id" },
      { title: "Mobile", dataIndex: "mobile" },
      { title: "Role", dataIndex: "company_user_role" },
      {
        title: "Actions",
        render: (r) => (
          <Space>
            <EditFilled
              className="action-icon"
              onClick={() => {
                setUser(r);
                setModalOpen(true);
              }}
            />
            <UnorderedListOutlined
              className="action-icon"
              onClick={() => navigate(`/company/${id}/user/${r?.id}/plan`)}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={__remove_company_user.bind(this, r)}
            >
              <DeleteFilled className="action-icon error" />
            </Popconfirm>
          </Space>
        ),
      },
    ];
  }, [__remove_company_user, id, navigate]);

  const handleMenuItemClick = useCallback(() => {
    let csvContent =
      "data:text/csv;charset=utf-8,Name,Email,Employee ID,Mobile\n";

    let encodeUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodeUri);
    link.setAttribute("download", `company_users_list_template.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const beforeUpload = useCallback((file) => {
    if (file.type !== "text/csv") {
      message.error("Upload only CSV Files");
      return false;
    }
    return true;
  }, []);

  const handleChange = useCallback(
    (e) => {
      if (e.file?.status === "done") {
        message.success("Users list imported!");
        __get_company_users(id);
      } else if (e?.file?.status === "error") {
        // Upload failed
      }
    },
    [__get_company_users, id]
  );

  return (
    <Layout.Content>
      <Row>
        <Col md={12}>
          <Typography.Title level={3}>Company Users</Typography.Title>
        </Col>
        <Col md={12} className="align-right">
          <Dropdown.Button
            type="primary"
            menu={{
              items: [{ key: "export_template", label: "Export CSV Template" }],
              onClick: handleMenuItemClick,
            }}
            style={{ justifyContent: "flex-end" }}
          >
            <Upload
              name="file"
              multiple={false}
              action={`${baseurl}/admin/company/${id}/users/import`}
              headers={{ Authorization: `Bearer ${getToken()}` }}
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              <ImportOutlined /> Import
            </Upload>
          </Dropdown.Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={users}
        loading={loading}
        rowKey="id"
      />

      <Button
        icon={<PlusOutlined />}
        className="fab"
        type="primary"
        shape="circle"
        onClick={() => {
          setModalOpen(true);
          setUser({});
        }}
      />

      <CompanyUserForm
        visible={modalOpen}
        close={(refresh = false) => {
          setModalOpen(false);
          setUser({});
          if (refresh) {
            __get_company_users(id);
          }
        }}
        company_id={id}
        user={user}
      />
    </Layout.Content>
  );
}
