import { Checkbox, Form, Modal } from "antd";
import React, { useCallback, useState } from "react";
import {
  ProductVariantPicker,
  WeekdayPicker,
} from "../../../components/pickers";
import { api, axios_error } from "../../../config";

const FormItem = Form.Item;

function CompanyUserPlanForm({ visible, close, userId }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      let data = await form.validateFields();
      await api.post(`/admin/company/user/${userId}/plan`, JSON.stringify(data));
      setLoading(false);
      form.resetFields();
      close(true);
    } catch (err) {
      axios_error(err);
      setLoading(false);
    }
  }, [form, userId, close]);

  return (
    <Modal
      title="Company User Plan"
      visible={visible}
      onOk={handleSubmit}
      onCancel={close.bind(this, false)}
      confirmLoading={loading}
    >
      <Form layout="vertical" form={form}>
        <FormItem
          label="Select Day"
          name="weekday"
          rules={[{ required: true, message: "Weekday is required!" }]}
        >
          <WeekdayPicker />
        </FormItem>
        <FormItem
          label="Product Variant"
          name="variant"
          rules={[{ required: true, message: "Product variant is required!" }]}
        >
          <ProductVariantPicker mode={null} />
        </FormItem>
        <FormItem name="available" initialValue={true} valuePropName="checked">
          <Checkbox>Enable</Checkbox>
        </FormItem>
      </Form>
    </Modal>
  );
}

export default CompanyUserPlanForm;
