import { Checkbox, Form, Modal } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { axios_error } from "../../../config";

const FormItem = Form.Item;

export default function UpdateProductUnavailability({
  open,
  close,
  product_id,
}) {
  const [days, setDays] = useState([]);

  const [form] = Form.useForm();

  // fetch unavailability for next few days and display them here

  const handleSubmit = useCallback(async () => {
    try {
      const body = await form.validateFields();
      let data = [];
      Object.keys(body).forEach((item) => {
        body[item]?.forEach((variant) => {
          data.push({ date: item, variant });
        });
      });
      console.log(data);
    } catch (err) {
      axios_error(err);
    }
  }, [form]);

  useEffect(() => {
    let _count = 7,
      _days = [];
    [...Array(_count).keys()].forEach((item) => {
      _days.push(moment().add(item, "days").format("YYYY-MM-DD"));
    });
    setDays(_days);
  }, []);

  return (
    <Modal
      title="Updated Product Unavailability"
      open={open}
      onOk={handleSubmit}
      onCancel={close}
    >
      <Form layout="vertical" form={form}>
        {days?.map((item, index) => {
          return (
            <FormItem
              key={index}
              name={[item]}
              label={item}
              valuePropName={"checked"}
            >
              <Checkbox.Group>
                <Checkbox value={"BREAKFAST"}>BREAKFAST</Checkbox>
                <Checkbox value={"LUNCH"}>LUNCH</Checkbox>
                <Checkbox value={"SNACKS"}>SNACKS</Checkbox>
                <Checkbox value={"DINNER"}>DINNER</Checkbox>
              </Checkbox.Group>
            </FormItem>
          );
        })}
      </Form>
    </Modal>
  );
}
